import {
  defaultFont,
  container,
  primaryColor,
  grayColor,
  whiteColor,
  drawerWidth
} from "assets/jss/material-dashboard-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    fontSize: "14px",
    float: "right!important"
  },
  center: {
    textAlign: "center"
  },

  footer: {
    backgroundColor: grayColor[12],
    right:"0",
    padding: "15px 0",
    ...defaultFont
  },
  container,
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  social_icon: {
    marginTop: "16px",
    marginRight: "32px"
  },
  info: {
     color: whiteColor,
    }
};
export default footerStyle;
